<div class="d-flex h-100 flex-column gap-3">
    <app-navigation-bar #nav [currentStage]="10"></app-navigation-bar>
    <div class="container pt-2 overflow-auto d-flex flex-column align-items-center flex-fill gap-3">
        @for (test of availableTests; track test.title) {
<!--            <h3 class="fw-bold m-0 align-self-start text-diagnosou-accent">{{test.description}}</h3>-->
            <div class="cursor-pointer p-3 d-flex rounded-3 mat-elevation-z3 gap-3 align-items-center w-100"
                 (click)="selectedTest = test"
                 [class.border]="selectedTest?.id === test.id"
                 [class.border-3]="selectedTest?.id === test.id"
                 [class.border-diagnosou-accent]="selectedTest?.id === test.id">
                @if (test.icon) {
                    @defer {
                        <img [src]="test.icon" alt="{{test.title}}" height="64"/>
                    } @loading (minimum 200) {
                        <div class="placeholder-glow opacity-50">
                            <div [ngStyle]="{height: '64px', width: '64px'}" class="placeholder rounded-2"></div>
                        </div>
                    } @placeholder (minimum 300) {
                        <div class="placeholder-glow opacity-50">
                            <div [ngStyle]="{height: '64px', width: '64px'}" class="placeholder rounded-2"></div>
                        </div>
                    }
                }
                <div class="flex-fill">
                    <h3 class="fw-bold">
                        {{ test.title }}
                    </h3>
                    <span class="fs-5 text-diagnosou-accent" [innerHTML]="test.description | markdown"></span>
                </div>

                <i class="fa fa-check-circle me-2 text-diagnosou-accent fs-1"
                   [hidden]="selectedTest?.id !== test.id">
                </i>
            </div>
        }
    </div>
    <div class="d-flex align-items-center m-md-5 m-3">
        <div class="d-none flex-fill d-md-block">

            <button class="btn btn-secondary back-btn fs-6 fw-bold w-auto d-md-block d-none"
                    (click)="nav.goBack()">
                {{'generic_back_btn'|translate}}
            </button>

        </div>
        <button [disabled]="!selectedTest"
                class="btn btn-gradient flex-grow-1 flex-md-grow-0"
                (click)="applyForTest()">
            {{ 'generic_button_next' | translate}}
        </button>
    </div>
</div>
