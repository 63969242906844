import {
    ChangeDetectorRef,
    Component,
    effect,
    ElementRef,
    EventEmitter,
    input,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    signal,
    ViewChild
} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {GoogleMap, GoogleMapsModule} from '@angular/google-maps';
import {CommonModule, NgIf} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {BackendService} from '../../services/backend.service';
import {catchError, Subject, Subscription, switchMap, of, debounceTime} from 'rxjs';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {LabService} from "../../services/lab.service";
import {NearbyLab} from "../../interfaces/Lab";
import {omit} from "lodash";
import {Router} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {fade} from "../../animations/enter-leave.animation";
import {LoaderComponent} from "../loader/loader.component";


@Component({
    selector: 'app-map',
    standalone: true,
    imports: [NgbModule, GoogleMapsModule, NgIf, CommonModule, FormsModule, FontAwesomeModule, TranslateModule, LoaderComponent],
    templateUrl: './map.component.html',
    styleUrl: './map.component.scss',
    animations: [
        fade
    ]
})

export class MapComponent implements OnInit, OnChanges, OnDestroy {
    private readonly DEFAULT_MAP_OPTIONS: google.maps.MapOptions = {
        mapId: "LABS_MAP",
        zoom: 13,
        zoomControl: false,
        streetViewControl: false,
        clickableIcons: false,
    }

    @Input() currentStage: number = 0;
    @Input() labs: any[] = [];

    @Output() currentStageChange: EventEmitter<number> = new EventEmitter<number>();

    searchInput = input.required<ElementRef | undefined>();

    mapInstance: google.maps.Map | undefined;
    center: google.maps.LatLngLiteral = {lat: 0, lng: 0};
    newLocation: google.maps.LatLngLiteral = {lat: 0, lng: 0};  // if the user drags the marker
    markerPosition: google.maps.LatLngLiteral = {lat: 0, lng: 0};
    marker: google.maps.marker.AdvancedMarkerElement | undefined;
    options: google.maps.MapOptions = {};
    content: any;
    labSelected = false;
    selectedLab: NearbyLab | undefined;
    fetchLabsByCoordinates = new Subject<boolean | void>(); // boolean to use map bounds, default is true

    // Search-related
    searchTerm: string = '';
    setAddress = new Subject<google.maps.places.PlaceResult>();

    nearbyLabs: (Partial<NearbyLab> & { id: string })[] = [];
    showEmptyLabsMsg = false;

    mapLoading = signal(false);
    mapLoadingTimeout: NodeJS.Timeout | undefined;

    autocomplete: google.maps.places.Autocomplete | undefined;

    private locationSub: Subscription | undefined;
    private _firstLoad = true;

    @ViewChild(GoogleMap, {static: false}) googleMap!: GoogleMap;

    protected mapLoad(event: google.maps.Map) {
        this.mapInstance = event;
        this.addYourLocationButton();
    }

    constructor(
        private ls: LocalStorageService,
        private session: SessionStorageService,
        private router: Router,
        private backendService: BackendService,
        private labService: LabService,
        private cd: ChangeDetectorRef
    ) {
        if (!this.labService.selectedTests()?.length) {
            this.router.navigate(['/test-select']);
            return;
        }

        this.fetchLabsByCoordinates.pipe(
            debounceTime(500),
            switchMap((useMapBounds) => {
                if (useMapBounds === false || !this.mapInstance) {
                    return of(undefined);
                }

                const mapBounds = this.mapInstance.getBounds();

                return of({
                    sw: [
                        mapBounds?.getSouthWest()?.lng()!,
                        mapBounds?.getSouthWest()?.lat()!
                    ],
                    ne: [
                        mapBounds?.getNorthEast()?.lng()!,
                        mapBounds?.getNorthEast()?.lat()!
                    ]
                })
            }),
            switchMap((bounds) => this.labService.getLabsByCoordinates(
                {
                    latitude: this.center.lat,
                    longitude: this.center.lng,
                },
                bounds,
                this.labService.selectedTests()?.map((test) => test.id)
            )),
            takeUntilDestroyed(),
            catchError((e) => {
                console.error('Failed to fetch labs', e);
                return of({success: false});
            }),
        ).subscribe((response) => {
            this.showEmptyLabsMsg = !response?.labs?.length && !this._firstLoad;
            this._firstLoad = false
            this._initLabs();

            if (!response?.labs) {
                this.cd.markForCheck();
                return;
            }

            for (const lab of response.labs) {
                this.nearbyLabs.push({
                    id: lab.id,
                    name: lab.displayName,
                    distance: lab.distance,
                    location: {lat: lab.coordinates[1], lng: lab.coordinates[0]},
                    content: this.createMarkerContent('lab'),
                    address: lab.address,
                    logo: lab.logo,
                    rating: lab.ratings?.stars,
                    ratingCount: lab.ratings?.count,
                    isOpen: lab.isOpen,
                    closesAt: lab.isOpen ? lab.closingMessage : lab.openNext,
                });
            }
            this.calculateLabDistance();
            this.cd.markForCheck();
        })

        this.setAddress.pipe(takeUntilDestroyed()).subscribe((place) => {
            if (place.geometry) {
                const searchCenter = place.geometry.location?.toJSON(); // Set the map center to the clicked result
                this.options = {...this.DEFAULT_MAP_OPTIONS, center: searchCenter};
                this.center = searchCenter ?? this.center;

                this._initLabs(searchCenter!);
                this.fetchLabsByCoordinates.next();
                this.currentStageChange.emit(1);

                this.cd.markForCheck();
            }
        });

        effect(() => {
            const input = this.searchInput()?.nativeElement;

            if (!input || !!this.autocomplete) {
                return
            }
            // Initialize the Google Places Autocomplete
            this.autocomplete = new google.maps.places.Autocomplete(input, {
                componentRestrictions: {country: 'GR'}, // Restrict to Greece
            });
            // Add a listener for when the user selects an address
            this.autocomplete.addListener('place_changed', this._placeChanged.bind(this));

        });

        effect(() => {
            clearTimeout(this.mapLoadingTimeout);
            if (this.mapLoading()) {
                this.mapLoadingTimeout = setTimeout(() => {
                    this.mapLoading.set(false);
                }, 5000);
            }
        });
    }

    ngOnInit() {

        const coords: google.maps.LatLngLiteral = this.session.retrieve('coords');
        const zoom: number = this.session.retrieve('zoom');
        const user_pin_coords: google.maps.LatLngLiteral = this.session.retrieve('user_pin_coords');

        if (!coords?.lat || !coords?.lng) {
            this.locationSub = this.backendService.location({})
                // .pipe(takeUntil(this._locationUnsub$))
                .subscribe(response => {
                    if (response?.latitude && response?.longitude && this._firstLoad) {
                        this.center = {lat: response.latitude, lng: response.longitude}
                        this.options = {...this.DEFAULT_MAP_OPTIONS, center: this.center};
                        this._firstLoad = true;

                        this.fetchLabsByCoordinates.next();
                    }
                });
        } else {
            this._initLabs(user_pin_coords ?? coords);
        }
        this.setCurrentLocation(coords, zoom);
    }

    ngOnDestroy() {
        if (this.autocomplete) {
            google.maps.event.clearInstanceListeners(this.autocomplete); // Remove all listeners
        }
        if (this.mapInstance) {
            google.maps.event.clearInstanceListeners(this.mapInstance); // Remove all listeners
        }
    }

    ngOnChanges(): void {
        if (this.currentStage === 0) {
            this._initLabs();
            this.labSelected = false;

            if (this.autocomplete) {
                google.maps.event.clearInstanceListeners(this.autocomplete); // Remove all listeners
            }
        }
    }

    addYourLocationButton() {
        const controlDiv = document.createElement('div');

        const firstChild = document.createElement('button');
        firstChild.style.backgroundColor = '#fff';
        firstChild.style.border = 'none';
        firstChild.style.outline = 'none';
        firstChild.style.width = '28px';
        firstChild.style.height = '28px';
        firstChild.style.borderRadius = '2px';
        firstChild.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)';
        firstChild.style.cursor = 'pointer';
        firstChild.style.marginRight = '10px';
        firstChild.style.padding = '0';
        firstChild.title = 'me';
        controlDiv.appendChild(firstChild);

        const secondChild = document.createElement('div');
        secondChild.style.margin = '5px';
        secondChild.style.width = '18px';
        secondChild.style.height = '18px';
        secondChild.style.backgroundImage = 'url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-2x.png)';
        secondChild.style.backgroundSize = '180px 18px';
        secondChild.style.backgroundPosition = '0 0';
        secondChild.style.backgroundRepeat = 'no-repeat';
        firstChild.appendChild(secondChild);

        if (this.mapInstance) {
            google.maps.event.addListener(this.mapInstance!, 'center_changed', function () {
                (secondChild.style as any)['background-position'] = '0 0';
            });
        }

        firstChild.addEventListener('click', () => {
            let imgX = '0';
            const animationInterval = setInterval(() => {
                imgX = imgX === '-18' ? '0' : '-18';
                (secondChild.style as any)['background-position'] = imgX + 'px 0';
            }, 500);

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    const latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                    this.mapInstance?.setCenter(latlng);
                    this.center = {lat: position.coords.latitude, lng: position.coords.longitude};
                    this.cd.markForCheck();
                    this.fetchLabsByCoordinates.next();
                    clearInterval(animationInterval);
                    (secondChild.style as any)['background-position'] = '-144px 0';
                });
            } else {
                clearInterval(animationInterval);
                (secondChild.style as any)['background-position'] = '0 0';
            }
        });
//@ts-ignore
        controlDiv.index = 1;
        this.mapInstance?.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(controlDiv);
    }

    changeLocationTo(coords: google.maps.LatLng) {
        if (!coords) {
            return;
        }
        const me = this.nearbyLabs?.find((lab) => lab.id === 'me');
        if (me) {
            me.location = {
                lat: coords.lat(),
                lng: coords.lng()
            };
            // this.fetchLabsByCoordinates.next();
            this.cd.markForCheck();
        }
    }

    setCurrentLocation(coords?: google.maps.LatLngLiteral, zoom?: number) {
        if (coords?.lng && coords?.lat) {
            this.center = coords;
            this.options = {...this.DEFAULT_MAP_OPTIONS, center: this.center, zoom: zoom ?? this.DEFAULT_MAP_OPTIONS.zoom};
            this.markerPosition = {lat: this.center.lat, lng: this.center.lng};
            this.fetchLabsByCoordinates.next();
            return;
        }

        if ('geolocation' in navigator) {
            // Add options to improve accuracy and handle timeout
            const geoOptions = {
                enableHighAccuracy: true, // Tries to use GPS or better sources
                timeout: 5000, // Wait up to 10 seconds before timing out
                maximumAge: 0, // Prevents caching of old positions
            };

            this.mapLoading.set(true);
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    if (!this.mapLoading()) {
                        return;
                    }
                    // this._locationUnsub$.next();
                    this.locationSub?.unsubscribe();
                    // Success callback: set map center and marker
                    this.center = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    this.options = {...this.DEFAULT_MAP_OPTIONS, center: this.center, zoom: zoom ?? this.DEFAULT_MAP_OPTIONS.zoom};
                    this.markerPosition = {lat: this.center.lat, lng: this.center.lng};
                    this._firstLoad = true;
                    this._initLabs(this.center);
                    this.fetchLabsByCoordinates.next();

                    this.mapLoading.set(false);
                },
                () => {
                    this.mapLoading.set(false);
                },
                geoOptions // Use the options to improve accuracy
            );
        }
    }

    showLabInfo(lab: Partial<NearbyLab>) {
        if (lab.id === 'me') {
            return;
        }
        let foundLab = this.nearbyLabs?.find((nearbylab) => nearbylab?.name === lab?.name)
        if (foundLab) {
            foundLab.content = this.createMarkerContent('selectedLab');
        }
        if (this.selectedLab) {
            foundLab = this.nearbyLabs?.find((nearbylab) => nearbylab?.name === this.selectedLab!.name);
            if (foundLab) {
                foundLab.content = this.createMarkerContent('lab');
            }
        }
        this.labSelected = true;
        this.selectedLab = lab as NearbyLab;
    }

    hideLabInfo() {
        this.labSelected = false;
        if (!this.selectedLab) {
            return;
        }
        const foundLab = this.nearbyLabs.find((nearbylab) => nearbylab?.name === this.selectedLab!.name)
        if (foundLab) {
            foundLab.content = this.createMarkerContent('lab');
        }
        this.selectedLab = undefined;
    }

    selectCurrentLab() {
        if (!this.selectedLab) {
            return;
        }
        this.labService.selectedLab.set(omit(this.selectedLab, 'content'));
        this.session.store('coords', this.options.center);
        this.session.store("zoom", this.mapInstance?.getZoom());
        this.session.store("user_pin_coords", this.nearbyLabs?.find((lab) => lab.id === 'me')?.location);
        this.router.navigate(['/eligibility']);
    }

    zoomChanged() {
        this.center = this.mapInstance?.getCenter()?.toJSON() ?? this.center;
        this.fetchLabsByCoordinates.next();
    }

    pinDragEnd(event: google.maps.MapMouseEvent) {
        const me = this.nearbyLabs?.find((lab) => lab.id === 'me');
        if (!me) {
            return;
        }
        me.location = event.latLng?.toJSON();
        this.calculateLabDistance();
        this.cd.markForCheck();
    }

    dragEnd() {
        // Update the lab's location with the new coordinates
        this.labSelected = false;
        this.selectedLab = undefined;
        const newCenter = this.googleMap.googleMap?.getCenter()
        const newLat = newCenter?.lat();
        const newLng = newCenter?.lng();

        this.newLocation = {lat: newLat ?? 0, lng: newLng ?? 0};

        this.options.center = this.newLocation;
        this.center = this.newLocation;
        this.currentStageChange.emit(1);
        this.fetchLabsByCoordinates.next();
        this.cd.markForCheck();

    }

    createMarkerContent(markerType: string): HTMLImageElement {
        const img = document.createElement('img');
        img.height = 64;
        if (markerType === 'myLocation') {
            img.height = 96;
            img.classList.add('pulsate');
            img.classList.add('pin');
            img.src = 'assets/images/user-pin.svg';
        }

        if (markerType === 'selectedLab') {
            img.src = 'assets/images/Selected lab.svg';
        }

        if (markerType === 'lab') {
            img.src = 'assets/images/All labs.svg';
        }

        return img;
    }

    calculateLabDistance() {
        const me = this.nearbyLabs?.find((lab) => lab.id === 'me');
        if (!me) {
            return;
        }

        // Radius of the Earth in kilometers
        const R = 6371;

        for (const lab of this.nearbyLabs) {
            if (lab.id === 'me') {
                continue;
            }

            // Calculate the difference in latitude and longitude in radians
            const dLat = (lab.location!.lat - me.location!.lat) * (Math.PI / 180);
            const dLng = (lab.location!.lng - me.location!.lng) * (Math.PI / 180);

            // Haversine formula to calculate the great-circle distance between two points
            const a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(me.location!.lat * (Math.PI / 180)) * Math.cos(lab.location!.lat * (Math.PI / 180)) *
                Math.sin(dLng / 2) * Math.sin(dLng / 2);

            // Calculate the distance in kilometers
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            lab.distance = R * c;
        }
    }

    private _placeChanged() {
        this.setAddress.next(this.autocomplete!.getPlace());
        this.searchTerm = '';
    }

    private _initLabs(coords?: google.maps.LatLngLiteral) {
        this.nearbyLabs = this.nearbyLabs.filter((lab) => lab.id === 'me');
        if (!this.nearbyLabs.length) {
            this.nearbyLabs.push(this.myLocationMarker);
        }
        if (coords) {
            this.nearbyLabs[0].location = coords;
        }
        if (!this.nearbyLabs[0].location?.lat || !this.nearbyLabs[0].location?.lng) {
            this.nearbyLabs[0].location = {
                lat: (typeof this.options.center?.lat === "number" ? this.options.center?.lat : this.options.center?.lat?.()) ?? this.center.lat!,
                lng: (typeof this.options.center?.lng === "number" ? this.options.center?.lng : this.options.center?.lng?.()) ?? this.center.lng!,
            };
        }
        this.labSelected = false;
        this.selectedLab = undefined;
    }

    private get myLocationMarker() {
        return {
            id: "me",
            name: "me",
            // location: this.center!,
            content: this.createMarkerContent('myLocation'),
        }
    }
}

