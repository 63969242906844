import {
    ChangeDetectorRef,
    Component,
    computed,
    effect,
    ElementRef,
    HostListener,
    OnInit, Signal,
    signal,
    ViewChild
} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CommonModule} from '@angular/common';
import {NavigationBarComponent} from '../../core/components/navigation-bar/navigation-bar.component';

import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDatepicker, MatDatepickerModule} from '@angular/material/datepicker';
import {provideMomentDateAdapter} from '@angular/material-moment-adapter';
import {FormsModule} from '@angular/forms';
import {DatepickerModalComponent} from '../../core/components/datepicker-modal/datepicker-modal.component';
import moment from 'moment';
import {Router} from '@angular/router';
import {LabService} from "../../core/services/lab.service";
import {finalize} from "rxjs";
import {ToastrService} from "ngx-toastr";
import {SessionStorageService} from "ngx-webstorage";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {MatTooltipModule} from "@angular/material/tooltip";
import {LoaderComponent} from "../../core/components/loader/loader.component";


export const MOMENT_DATE_FORMATS = {
    parse: {
        dateInput: 'dddd, MMMM DD',
    },
    display: {
        dateInput: 'dddd, MMMM DD',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-appointment',
    standalone: true,
    providers: [provideMomentDateAdapter(MOMENT_DATE_FORMATS, {useUtc: true})],
    imports: [NgbModule, CommonModule, NavigationBarComponent, MatFormFieldModule, MatInputModule, MatDatepickerModule, FormsModule, TranslateModule, MatTooltipModule, LoaderComponent],
    templateUrl: './appointment.component.html',
    styleUrl: './appointment.component.scss'
})
export class AppointmentComponent implements OnInit {
    currentStage: number = 9;
    clickedInside: boolean = false;
    showEmptySlotsMessage: boolean = false;

    loading = signal<boolean>(false);
    selectedDate = signal<Date | null>(null);
    timeBoxArray: { displayTime: string; time: string; available: boolean; onFire?: boolean }[] = [];
    rescheduling: Signal<boolean>;

    datepickerHeader = DatepickerModalComponent;
    date = null;
    currentDate = new Date();
    maxDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 6, this.currentDate.getDate());
    selectedTime: string | null = null;

    @ViewChild('picker') picker: MatDatepicker<any> | undefined;
    @ViewChild('timeBoxContainer') timeBoxContainer: ElementRef | undefined;

    constructor(
        private cd: ChangeDetectorRef,
        private router: Router,
        private toastr: ToastrService,
        private session: SessionStorageService,
        private translate: TranslateService,
        public labService: LabService
    ) {
        this.rescheduling = computed(() => !!this.labService.rescheduleId());

        if (!this.labService.selectedTests()?.length) {
            this.router.navigate(['/test-select']);
            return;
        }
        if (!this.labService.selectedLab()) {
            this.router.navigate(['/lab-select']);
            return;
        }

        effect(() => {
            if (!this.selectedDate() || !this.labService.selectedLab()?.id) {
                console.error(`Selected date or lab is not set | selectedDate: ${this.selectedDate()} | selectedLab: ${this.labService.selectedLab()?.id}`);
                return;
            }

            this.selectedTime = null;

            this.labService.getAvailableSlots(moment(this.selectedDate()!).format("yyyy-MM-DD"), this.labService.selectedLab()!.id).subscribe((res) => {
                if (res.success) {
                    this.timeBoxArray = res.slots ?? [];
                    this.showEmptySlotsMessage = !this.timeBoxArray.length;
                    this.cd.markForCheck();
                    setTimeout(() => {
                        this.timeBoxContainer?.nativeElement.scrollIntoView({
                            behavior: "smooth",
                            block: "start"
                        });
                    }, 300);
                }
            });
        });
    }

    ngOnInit(): void {
        // this.selectedDate.set(new Date());
    }

    onChanged() {
        setTimeout(() => {
            moment.locale("el");
            const date = moment(this.date);
            console.log(date.locale())
            this.selectedDate.set(date.toDate());
        });
    }

    onCurrentStageChange(newStage: number) {
        this.currentStage = newStage; // Update the currentStage based on the navigation bar input
    }

    selectBox(time: string) {
        this.selectedTime = time; // Set the selected box ID when clicked
    }

    bookAppointment() {
        this.loading.set(true);
        const appointmentDate = moment(this.selectedDate()).format("yyyy-MM-DD");
        const appointmentTime = this.selectedTime
        const lab = this.labService.selectedLab()?.id;
        const tests = this.labService.selectedTests()?.map((test) => ({
            id: test.id,
            quantity: 1
        }));
        const rescheduleId = this.labService.rescheduleId();
        const ssnReqId = this.session.retrieve("ssnReqId");

        (rescheduleId
                ? this.labService.rescheduleAppointment(
                    rescheduleId!,
                    appointmentDate,
                    appointmentTime!,
                    this.labService.rescheduleReasonId()!,
                    this.labService.rescheduleReasonComment()!
                )
                : this.labService.bookAppointment(
                    tests!,
                    lab!,
                    appointmentDate,
                    appointmentTime!,
                    !!this.session.retrieve("privateAppointment"),
                    ssnReqId
                )
        )
            .pipe(finalize(() => this.loading.set(false)))
            .subscribe((res) => {
                if (res.success) {
                    this.session.store("appointment_summary", {
                        lab: this.labService.selectedLab(),
                        date: moment(appointmentDate).startOf('day')
                            .add(appointmentTime?.split(":")[0], 'hours')
                            .add(appointmentTime?.split(":")[1], 'minutes')
                            .format("dddd DD MMMM, HH:mm"),
                        bookingNo: res.bookingNo
                    });

                    this.session.clear("privateAppointment");
                    this.session.clear("ssnReqId");
                    this.labService.rescheduleId.set(null);
                    this.labService.rescheduleReasonId.set(null);
                    this.labService.rescheduleReasonComment.set(null);

                    this.router.navigate(['/sms-confirmation'], {
                        queryParams: {
                            message1: this.translate.instant("appointment_completion_title"),
                            message2: this.translate.instant("appointment_completion_subtitle"),
                            thanks: true,
                            summary: true,
                            bookingConf: true
                        }
                    });
                    return;
                }

                if (res.appointments?.length) {
                    this.router.navigate(['/existing-appointment']);
                    return;
                }

                this.toastr.error(res.message ?? "Υπήρξε πρόβλημα κατά την καταχώρηση του ραντεβού σας. Παρακαλώ δοκιμάστε ξανά.");

            })
    }

    onInputClick() {
        this.clickedInside = true; // Set flag to true when input is clicked
        this.picker?.open(); // Open the datepicker
    }

    @HostListener('document:click', ['$event'])
    handleOutsideClick(event: Event) {
        const datepickerElement = document.querySelector('.mat-datepicker-content');

        if (this.picker?.opened && !this.clickedInside && (!datepickerElement || !datepickerElement.contains(event.target as Node))) {
            this.picker.close();
        }

        this.clickedInside = false;
    }
}
