import {Component} from '@angular/core';
import {NavigationBarComponent} from "../../core/components/navigation-bar/navigation-bar.component";
import {CommonModule} from "@angular/common";
import {LabService} from "../../core/services/lab.service";
import {Router} from "@angular/router";
import {Exam} from "../../core/interfaces/Exam";
import {ExamService} from "../../core/services/exam.service";
import {TranslateModule} from "@ngx-translate/core";
import {MarkdownPipe} from "../../core/pipes/markdown.pipe";

@Component({
    selector: 'app-test-selection',
    standalone: true,
    imports: [
        CommonModule,
        NavigationBarComponent,
        TranslateModule,
        MarkdownPipe,
    ],
    templateUrl: './test-selection.component.html',
    styleUrl: './test-selection.component.scss'
})
export class TestSelectionComponent {

    availableTests: Exam[] = [];
    selectedTest: Exam | undefined;

    constructor(
        private labService: LabService,
        private router: Router,
        private examService: ExamService
    ) {
        this.examService.retrieve().subscribe((res) => {
            this.availableTests = res.exams?.map((exam: Exam) => ({
                ...(exam ?? {}),
                icon: exam?.icon ?? 'assets/images/App Logo.svg'
            }));
        });
    }

    applyForTest() {
        if (!this.selectedTest?.id) {
            return;
        }
        this.labService.selectedTests.set([this.selectedTest!]);
        this.router.navigate(['/lab-select']);
    }
}
